import type { VideoHTMLAttributes } from "react";
import { useContext } from "react";
import { CloudinaryContext } from "./Provider";
import { CloudinaryTransformationParamKey } from "./constants";

const cloudinaryVideoDefaultTransformations = [
  "q_auto",
  "f_auto",
  "dpr_2.0",
  "c_scale",
];

type CloudinaryVideoTypeNoSrc = Omit<
  VideoHTMLAttributes<HTMLVideoElement>,
  "src"
>;

interface CloudinaryVideoProps extends CloudinaryVideoTypeNoSrc {
  transformations?: Array<CloudinaryTransformationParamKey>;
  src?: { type?: string; url?: string; formats?: [any] };
  videoRef?: any;
}

export function CloudinaryVideo({
  src,
  transformations,
  videoRef,
  ...props
}: CloudinaryVideoProps) {
  const { cloudName, autoUploadMappingFolder } = useContext(CloudinaryContext);
  const stringifiedParams = cloudinaryVideoDefaultTransformations
    .concat(transformations || [])
    .join(",");

  const cloudinaryStagingRoot = `https://res.cloudinary.com/${cloudName}/video/upload/${stringifiedParams}/v1/${autoUploadMappingFolder[0]?.folder}/`;
  const cloudinaryProductionRoot = `https://res.cloudinary.com/${cloudName}/video/upload/${stringifiedParams}/v1/${autoUploadMappingFolder[0]?.folder}/`;

  let url = src?.url
    ?.replace(`${autoUploadMappingFolder[0]?.urlPrefix}`, cloudinaryStagingRoot)
    ?.replace(
      `${autoUploadMappingFolder[0]?.urlPrefix}`,
      cloudinaryProductionRoot
    );

  if (!url || !url.includes("cloudinary")) {
    const appendRoot =
      `https://res.cloudinary.com/${cloudName}/video/upload/${stringifiedParams}/v1/` +
      url;
    url = appendRoot;
  }

  if (
    url &&
    !url.includes(stringifiedParams) &&
    !url.includes("/v1/s3bucket/videos/")
  ) {
    url = url.replace(
      `https://res.cloudinary.com/${cloudName}/video/upload/`,
      `https://res.cloudinary.com/${cloudName}/video/upload/${stringifiedParams}/`
    );
  }

  return (
    <video
      {...props}
      ref={videoRef}
      key={url}
      controls
      controlsList="nodownload"
    >
      <source src={`${url}#t=0.1`} type="video/mp4" />
    </video>
  );
}

export default CloudinaryVideo;
