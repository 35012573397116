import { Dispatch, SetStateAction, useMemo } from "react";
import { formatLocation } from "../../../../MultiCardModal/components/LocationSearch";
import CountryCityPicker from "./CountryCityPicker";
import SearchCard, { OpenDialogProps } from "./SearchCard";

type LocationSearchProps = OpenDialogProps & {
  id: string | null;
  setId: Dispatch<SetStateAction<string | null>>;
  setCountry: Dispatch<SetStateAction<string | null>>;
};
export default function LocationSearch({
  id,
  setId,
  setCountry,
  ...props
}: LocationSearchProps) {
  const value = useMemo(() => formatLocation(id), [id]);

  return (
    <SearchCard label="Where" value={value} {...props}>
      <div
        style={{
          width: 160,
        }}
      >
        <CountryCityPicker
          selectedId={id}
          onCountryPick={(countryId) => {
            setCountry(countryId);
            props.openNext?.();
          }}
          onCityPick={(locationId) => {
            setId(locationId);
            props.openNext?.();
          }}
        />
      </div>
    </SearchCard>
  );
}
