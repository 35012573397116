import styles from "./Footer.module.css";

import Link from "next/link";
import { useRouter } from "next/router";

import { HiUserGroup } from "react-icons/hi";
import { IoLibrary } from "react-icons/io5";
import { MdQuestionAnswer as QuestionsIcon } from "react-icons/md";
import AcceptedPayments from "./AcceptedPayments";
import FooterNavigation from "./FooterNavigation";
import FooterTA from "./FooterTA";
import FooterTourList from "./FooterTourList";
import PartnerList from "./Partner";

export default function Footer() {
  const router = useRouter();
  const isCheckout =
    router.pathname === "/bookings/[id]" ||
    router.pathname === "/checkout/[id]" ||
    router.pathname === "/payments/[id]"
      ? true
      : false;

  const hideFooter =
    isCheckout ||
    router.pathname === "/guide/[guideId]/sign-contracts" ||
    router.pathname === "/guide/sign-contracts-success";

  return (
    <section style={hideFooter ? { display: "none" } : null}>
      <FooterTA />
      <FooterNavigation />
      <FooterTourList />

      <footer>
        <div className={styles.AppFooter}>
          <div
            className={styles.AppFooterInner}
            style={{
              background: "linear-gradient(90deg, #f44336, #e91e63)",
            }}
          >
            <div className={styles.footAbout}>
              <h6>MagicalTrip Inc.</h6>

              <ul>
                <li>
                  <Link
                    href="/terms-of-service"
                    className={styles.AppFooterTextLink}
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    href="/privacy-policy"
                    className={styles.AppFooterTextLink}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.AppFooterTextLink}
                    href="/external-transmission-rule"
                  >
                    External Transmission Rule
                  </Link>
                </li>

                <li>
                  <Link
                    href="/Standard_General_Conditions_of_Travel_Agency_Business_For_MT_Web_Site.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textAlign: "left" }}
                  >
                    Sign (Registration Form) & Standard General Conditions of
                    Travel Agency Business
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.AppFooterColumns}>
              <div className={styles.AppFooterCol}>
                <div>
                  <h6 className={styles.FooterHeading}>About MagicalTrip</h6>
                  <ul>
                    <li>
                      <Link
                        href="/about-us?ref=footer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>About Us</span>
                      </Link>
                    </li>
                    <li
                      style={{
                        position: "relative",
                      }}
                    >
                      <Link
                        href="/media?ref=footer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Our Blog</span>
                      </Link>
                    </li>
                  </ul>
                  <h6
                    className={styles.FooterHeading}
                    style={{ marginTop: 30 }}
                  >
                    Work With Us
                  </h6>
                  <ul>
                    <li>
                      <Link
                        href="/recruit?ref=footer"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.AppFooterTextLink}
                      >
                        Become a MagicalTrip Guide
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/business-partners?ref=footer"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.AppFooterTextLink}
                      >
                        As a Travel Agency
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/business-partners?ref=footer"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.AppFooterTextLink}
                      >
                        As a Content Creator
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.AppFooterCol}>
                <h6 className={styles.FooterHeading}>Support</h6>
                <ul>
                  <li className={styles.FooterIconLink}>
                    <Link href="/contact-us">
                      <span className={styles.FooterIconLinkIcon}>
                        <QuestionsIcon color={"#fff"} size={24} />
                      </span>
                      <span>Contact Us</span>
                    </Link>
                  </li>
                  <li className={styles.FooterIconLink}>
                    <Link href="/big-group-tours?ref=footer">
                      <span className={styles.FooterIconLinkIcon}>
                        <HiUserGroup color={"#fff"} size={24} />
                      </span>
                      <span>Booking for Big Groups</span>
                    </Link>
                  </li>
                  <li className={styles.FooterIconLink}>
                    <Link href="/faq?ref=footer">
                      <span className={styles.FooterIconLinkIcon}>
                        <IoLibrary color={"#fff"} size={24} />
                      </span>
                      <span>Frequently Asked Questions</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className={styles.AppFooterCol}>
                <h6 className={styles.FooterHeading}>As Featured On</h6>
                <PartnerList />
                <h6 className={styles.FooterHeading}>Ways You Can Pay</h6>
                <AcceptedPayments />
                <ul>
                  <li className={styles.ContactAddress}>
                    <p>
                      Certified Travel License: <br />
                      Tokyo Metropolitan Government Office: No.2-7941
                      <br />
                      <br />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.BottomFooter}>
            <div className="AppLayout">
              <div
                className={styles.AppFooterInner}
                style={{
                  display: "flex",
                  padding: 0,
                }}
              >
                <div className={styles.AppFooterCopyright}>
                  <small>
                    © {new Date().getFullYear()} MagicalTrip™ /
                    マジカルトリップ™
                  </small>
                </div>

                <ul className={styles.AppFooterNav}>
                  <li className={styles.AppFooterTap}>
                    <Link href="/terms-of-service">Terms of Service</Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/external-transmission-rule">
                      External Transmission Rule
                    </Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link
                      href="/Standard_General_Conditions_of_Travel_Agency_Business_For_MT_Web_Site.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textAlign: "left" }}
                    >
                      Sign (Registration Form) & Standard General Conditions of{" "}
                      <br />
                      Travel Agency Business
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}
