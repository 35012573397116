import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { locationIds } from "../../../../core/enums";
import { CityTiles } from "../../CityNavigation";
import styles from "../SearchModal.module.scss";
import { formatLocation } from "./LocationSearch";
import SearchCard, { SearchCardProps } from "./SearchCard";

type LocationSearchCardProps = Omit<SearchCardProps, "label"> & {
  locationId: string;
  setLocationId: Dispatch<SetStateAction<string>>;
  setCountryId: Dispatch<SetStateAction<string>>;
  countryId: string;
};

export default function LocationSearchCard({
  countryId,
  locationId,
  setLocationId,
  setCountryId,
  ...searchCardProps
}: LocationSearchCardProps) {
  const countryIds = [locationIds.THAILAND, locationIds.JAPAN];
  const [selectedCountry, setSelectedCountry] = useState(
    countryIds.includes(locationId) ? locationId : countryId
  );

  const handleCountryClick = (locationId: string) => {
    if (selectedCountry === locationId) {
      // just want the country so just go next step
      setCountryId(locationId);
      searchCardProps.expandNextCard();
    }
    setSelectedCountry(locationId);
    setLocationId(locationId);
  };

  useEffect(() => {
    if (locationId) {
      searchCardProps.expandNextCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchCard {...searchCardProps} label={formatLocation(locationId)}>
      <h5 className={styles.LocationHeader}>Country</h5>

      <div className={styles.CountryTileContainer}>
        <div
          className={`${styles.CountryTile} ${
            selectedCountry === locationIds.JAPAN ? styles.highlighted : ""
          }`}
          onClick={() => handleCountryClick(locationIds.JAPAN)}
        >
          <span>Japan</span>
        </div>
        <div
          className={`${styles.CountryTile} ${
            selectedCountry === locationIds.THAILAND ? styles.highlighted : ""
          }`}
          onClick={() => handleCountryClick(locationIds.THAILAND)}
        >
          <span>Thailand</span>
        </div>
        <div
          className={`${styles.CountryTile} ${
            !locationId ? styles.highlighted : ""
          }`}
          onClick={() => {
            setCountryId(undefined);
            setSelectedCountry(undefined);
            setLocationId(undefined);
            searchCardProps.expandNextCard();
          }}
        >
          <span>Anywhere</span>
        </div>
      </div>
      {locationId && (
        <div className={styles.LocationCityContainer}>
          <h5 className={styles.LocationHeader}>City</h5>
          <CityTiles
            includeAnywhere
            countryId={selectedCountry}
            highlightId={locationId}
            onClick={(city) => {
              setLocationId(city.id);
              setSelectedCountry(city.country.id);
              setCountryId(city.country.id);
              searchCardProps.expandNextCard();
            }}
          />
        </div>
      )}
    </SearchCard>
  );
}
