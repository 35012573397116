export const paymentTypes = {
  ALIPAY: "Alipay",
  CARD: "Card",
  PAYPAL: "Paypal",
  UNIONPAY: "UnionPay",
};

export const countryCodes = {
  JP: "JP",
};

export const groupTypes = {
  COUPLE: "COUPLE",
  FAMILY: "FAMILY",
  FRIENDS: "FRIENDS",
  SOLO: "SOLO",
};

export const locationIds = {
  HIROSHIMA: "9ae6ec82-75b7-a1ae-9b48-a68b9e376e0f",
  KYOTO: "c0de54d6-b39c-fd15-a578-750e4bc26fc7",
  OSAKA: "5ea1a33e-a082-eef5-bc24-7dad4e310488",
  TOKYO: "96d199a0-87fe-3e1e-3cf3-20f4b27d5fef",
  NARA: "a837fec7-6f30-4e51-c047-708abe7b690b",
  HOKKAIDO: "b2d7e01f-70e2-ed86-fe7b-5cb51e777fc7",
  HYOGO: "a7367b88-3881-ca60-c3df-4fb013320cb8",
  KENROKUEN: "77e8df68-ea76-743a-49a5-c69497aed200",
  BANGKOK: "3d7f39d1-e25a-40be-914c-620e61708126",
  YOKOHAMA: "67ca14a9-aaea-4254-9ab8-c992802110a4",
  OKINAWA: "f4de2e31-f967-406f-bed1-94a8afff18cf",
  ISHIKAWA: "bc46f25b-0912-4f2a-819f-eb703d46c334",
  JAPAN: "a032f3f0-81a8-41ac-bf04-f46e3be952a5",
  THAILAND: "d6ab9244-a663-43b3-b5ac-92064879d22b",
};

export const tourSortingTypes = {
  AVAILABILITY: "AVAILABILITY",
  POPULARITY: "POPULARITY",
  CREATION_RECENCY: "CREATION_RECENCY",
};

export const trafficSources = {
  GOOGLE_CPC: "GOOGLE_CPC",
};

export const keywordMap: { [key: string]: string } = {
  [locationIds.TOKYO]: "Shinjuku, Shibuya, Asakusa",
  [locationIds.OSAKA]: "Dotonbori, Namba, Kuromon",
  [locationIds.KYOTO]: "Fushimi Inari, Arashiyama, Pontocho",
  [locationIds.HIROSHIMA]: "Heiwa, Miyajima",
  [locationIds.NARA]: "Deer park, Tōdaiji-ji",
  [locationIds.HOKKAIDO]: "Sapporo, Susukino",
  [locationIds.HYOGO]: "Himeji castle, Hyogo",
  [locationIds.KENROKUEN]: "Kenrokuen Garden, Samurai District",
  [locationIds.BANGKOK]: "Bang Rak, Chinatown",
  [locationIds.YOKOHAMA]: "Yokohama, Kamakura, Odawara",
  [locationIds.OKINAWA]: "Naha",
  [locationIds.ISHIKAWA]: "Kanazawa, Kenroku-en",
};
