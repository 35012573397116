import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { NextRouter } from "next/router";
import { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { keywordMap } from "../../../core/enums";
import { getCityUrl } from "../../../utils/getCityUrl";
import Loader from "../../Atoms/Loader";
import { Text } from "../../Atoms/Typography";
import { GET_NAV_LOCATIONS, GetLocationsResponse } from "./DestinationPicker";
import styles from "./Header.module.scss";

type DestinationPickerProps = {
  router: NextRouter;
};

export default function MobileDestinationPicker({
  router,
}: DestinationPickerProps) {
  const [expandedCountry, setExpandedCountry] = useState<string | null>(null);
  const { loading, error, data } = useQuery<GetLocationsResponse>(
    GET_NAV_LOCATIONS,
    { fetchPolicy: "cache-and-network" }
  );

  if (loading) {
    return <Loader />;
  }

  if (error || !data || !data.locations) {
    return <>Could not load cities.</>;
  }
  const { locations } = data;

  const countries = locations.records.reduce((acc, record) => {
    const { country } = record;
    if (!acc.some((c) => c.id === country.id)) {
      acc.push({
        id: country.id,
        name: country.name,
      });
    }
    return acc;
  }, [] as { id: string; name: string }[]);

  const handleCountryClick = (country: { id: string; name: string }) => {
    if (expandedCountry === country.id) {
      setExpandedCountry(null);
    } else {
      setExpandedCountry(country.id);
    }
  };

  return (
    <ul className={styles.Accordion}>
      {countries.map((country) => {
        const isExpanded = expandedCountry === country.id;
        const cities = locations.records.filter(
          (location) => location.country.id === country.id
        );

        return (
          <li key={country.id} className={styles.AccordionItem}>
            <div
              className={classNames(styles.AccordionHeader, {
                [styles.Expanded]: isExpanded,
              })}
              onClick={() => handleCountryClick(country)}
            >
              <b>{country.name}</b>
              {isExpanded ? (
                <FaChevronDown size={18} />
              ) : (
                <FaChevronRight size={18} />
              )}
            </div>
            <ul
              className={classNames(styles.CitiesList, {
                [styles.Show]: isExpanded,
              })}
            >
              <li
                className={styles.CityItem}
                onClick={() =>
                  router.push(`/tours-${country.name.toLowerCase()}`)
                }
              >
                <div className={styles.DestinationPickerItem}>
                  <b>All Cities</b>
                </div>
              </li>
              {cities.map((city) => (
                <li
                  key={city.id}
                  className={styles.CityItem}
                  onClick={() =>
                    router.push(
                      `/tours-${city.country.name.toLowerCase()}/${getCityUrl(
                        city
                      )}`
                    )
                  }
                >
                  <div className={styles.DestinationPickerItem}>
                    <b>{city.translation.name}</b>
                    <Text size="smaller" muted>
                      {city.destinationNames ?? keywordMap[city.id]}
                    </Text>
                  </div>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
