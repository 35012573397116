import Modal, { ModalProps } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import s from "./BasicModal.module.scss";

type BasicModalProps = ModalProps & {
  title?: string;
  endComponent?: JSX.Element;
  height?: ModalProps["styles"]["modal"]["height"];
};
export default function BasicModal({
  title,
  children,
  endComponent,
  height = "85vh",
  ...props
}: React.PropsWithChildren<BasicModalProps>) {
  return (
    <Modal
      styles={
        height
          ? {
              modal: {
                height,
              },
            }
          : {}
      }
      classNames={{
        root: s.CustomRoot,
        modal: s.CustomModal,
      }}
      {...props}
      blockScroll={false}
    >
      {title && <h1 className={s.Title}>{title}</h1>}
      <div className={s.ModalContainer}>{children}</div>
      {endComponent && <div className={s.EndComponent}>{endComponent}</div>}
    </Modal>
  );
}
