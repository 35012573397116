import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { IoIosReturnRight } from "react-icons/io";
import { keywordMap } from "../../../../../../core/enums";
import {
  Location,
  LocationTranslation,
} from "../../../../../../graphql/operations";
import Loader from "../../../../../Atoms/Loader";
import { Text } from "../../../../../Atoms/Typography";
import styles from "../../../SearchBar.module.scss";
type CountryCityPickerProps = {
  onCityPick: (locationId: Location["id"] | undefined) => void;
  onCountryPick: (locationId: Location["id"] | undefined) => void;
  selectedId?: string;
};

export default function CountryCityPicker({
  onCityPick,
  onCountryPick,
  selectedId,
}: CountryCityPickerProps) {
  const { loading, error, data } =
    useQuery<GetLocationsResponse>(GET_LOCATIONS);

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedTop, setSelectedTop] = useState<number | null>(null);
  const countryListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (typeof document !== "undefined") {
      const dialog = document.querySelector("dialog");
      if (selectedCountry) {
        dialog.style.borderTopRightRadius = "0px";
        dialog.style.borderBottomRightRadius = "0px";
      } else {
        dialog.style.borderTopRightRadius = "";
        dialog.style.borderBottomRightRadius = "";
      }
    }
  }, [selectedCountry]);

  if (loading) {
    return <Loader />;
  }

  if (error || !data || !data.locations) {
    return <>Could not load cities.</>;
  }

  const countries = data.locations.records.reduce<Country[]>((acc, record) => {
    const { country, translation } = record;
    const existingCountry = acc.find((c) => c.id === country.id);
    if (existingCountry) {
      existingCountry.cities.push({
        id: record.id,
        name: translation.name,
        destinationNames: record.destinationNames,
      });
    } else {
      acc.push({
        id: country.id,
        name: country.name,
        cities: [
          {
            id: record.id,
            name: translation.name,
            destinationNames: record.destinationNames,
          },
        ],
      });
    }
    return acc;
  }, []);

  const handleCountryClick = (countryId: string, index: number) => {
    if (countryId === selectedCountry) {
      onCityPick(countryId); //  if already selected again it means they just want the country
      onCountryPick(countryId);
    } else {
      setSelectedCountry(countryId);

      // Set the position of the city list
      if (countryListRef.current) {
        const countryListItem = countryListRef.current.children[
          index
        ] as HTMLElement;
        setSelectedTop(countryListItem.offsetTop);
      }
    }
  };

  return (
    <div className={styles.CountryCityPicker} style={{ display: "flex" }}>
      <ul ref={countryListRef}>
        {countries.map((country, index) => (
          <li
            key={country.id}
            onClick={() => handleCountryClick(country.id, index)}
            className={classNames(styles.CountryPickerItem, {
              [styles.Selected]: country.id === selectedCountry,
            })}
            style={selectedCountry === country.id ? { fontWeight: "bold" } : {}}
          >
            {country.name}
            {selectedCountry === country.id && <IoIosReturnRight size={20} />}
          </li>
        ))}

        <li
          className={styles.CountryPickerItem}
          onClick={() => {
            setSelectedCountry(undefined);
            onCityPick(undefined);
            onCountryPick(undefined);
          }}
        >
          Anywhere
        </li>
      </ul>

      {selectedCountry && (
        <div
          style={{
            display: "block",
            position: "absolute",
            top: selectedTop - 1 ?? 0,
          }}
          className={styles.CityPickerContainer}
        >
          <ul>
            {countries
              .find((country) => country.id === selectedCountry)
              ?.cities.map((city) => (
                <li
                  key={city.id}
                  onClick={() => {
                    onCityPick(city.id);
                    onCountryPick(selectedCountry);
                  }}
                  className={classNames(styles.CityPickerItem, {
                    [styles.Selected]: city.id === selectedId,
                  })}
                >
                  <b>{city.name}</b>
                  <Text size="smaller" muted>
                    {city.destinationNames ?? keywordMap[city.id]}
                  </Text>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

type Country = {
  id: string;
  name: string;
  cities: { id: Location["id"]; name: string; destinationNames: string }[];
};

type GetLocationsResponse = {
  locations: {
    records: (Pick<Location, "id" | "destinationNames" | "country"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

const GET_LOCATIONS = gql`
  query CountryCityPickerQuery {
    locations(
      input: {
        hasProducts: true
        locationTypes: [COUNTRY, PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      records {
        id
        destinationNames
        country {
          id
          name
        }
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
