import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { locationIds } from "../../../core/enums";
import { Location, LocationTranslation } from "../../../graphql/operations";
import navData from "../../../prebuild/data/navDataGenerated.json";
import { getCityUrl } from "../../../utils/getCityUrl";
import Loader from "../../Atoms/Loader";
import {
  GET_NAV_LOCATIONS,
  GetLocationsResponse,
} from "../../Layout/Header/DestinationPicker";
import CityPoster from "../CityPoster";
import styles from "./CityNavigation.module.css";

const countries = [
  {
    id: locationIds.THAILAND,
    slug: "thailand",
    country: {
      id: locationIds.THAILAND,
      name: "Thailand",
    },
    url: "/tours-thailand",
  },
  {
    id: locationIds.JAPAN,
    slug: "japan",
    country: {
      id: locationIds.JAPAN,
      name: "Japan",
    },
    url: "/tours-japan",
  },
];

type PartialCity = Pick<Location, "id" | "slug"> & {
  country?: Pick<Location, "id" | "name">;
  poster?: string;
  url?: string;
  translation?: Pick<LocationTranslation, "name">;
};

type CityTilesProps = {
  includeAnywhere?: boolean;
  onClick?: (city: PartialCity) => void;
  highlightId?: string;
  countryId?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">;

export function CityTiles({
  countryId,
  includeAnywhere,
  ...props
}: CityTilesProps) {
  const initialNavData = navData as unknown as GetLocationsResponse;

  const { loading, data } = useQuery<GetLocationsResponse>(GET_NAV_LOCATIONS, {
    fetchPolicy: "cache-and-network",
  });

  const locationsToUse = useMemo(
    () => data?.locations.records ?? initialNavData?.locations.records ?? [],
    [initialNavData, data]
  );

  const nav = useMemo(
    () =>
      locationsToUse.map((city) => ({
        ...city,
        poster: city.slug,
        url: `/tours-${city.country.name.toLowerCase()}/${getCityUrl(city)}`,
      })),
    [locationsToUse]
  );

  if (loading && locationsToUse.length === 0) return <Loader />;

  return (
    <div className={styles.CityTabContainer}>
      {(countryId ? nav.filter((c) => c.country.id === countryId) : []).map(
        (city) => (
          <CityTile key={city.id} city={city} {...props} />
        )
      )}
      {includeAnywhere && (
        <div
          className={styles.cityTab}
          style={{ textAlign: "center" }}
          onClick={() =>
            props.onClick(countries.find((c) => c.id === countryId))
          }
        >
          <span>All Cities</span>
        </div>
      )}
    </div>
  );
}

type CityTileProps = {
  city: PartialCity;
} & CityTilesProps;
function CityTile({ city, highlightId, onClick, ...divProps }: CityTileProps) {
  return (
    <div
      key={city.id}
      {...divProps}
      className={classNames(styles.cityTab, {
        [styles.highlighted]: city.id === highlightId,
      })}
      onClick={() => onClick(city)}
    >
      {city.poster && (
        <div key={city.translation.name} className={styles.cityTabImage}>
          <CityPoster name={city.poster} width={75} />
        </div>
      )}
      <span
        style={{
          ...(!city.poster && { marginLeft: 75 }),
        }}
      >
        {city.translation.name}
      </span>
    </div>
  );
}

export default function CityNavigation({ countryId }: { countryId: string }) {
  const router = useRouter();

  return (
    <CityTiles
      countryId={countryId}
      onClick={(city) => router.push(city.url)}
    />
  );
}
