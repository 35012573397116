import { FaChevronUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import useWindowSize from "../../utils/useWindowSize";
import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}
export default function Layout({ children }: LayoutProps) {
  const { width } = useWindowSize();

  const scrollBtn = {
    background: "#fff",
    width: 36,
    borderRadius: "50%",
    height: 36,
    padding: 0,
    border: "none",
  };
  return (
    <>
      <Header />
      <main className="AppLayoutContainer light-mode">{children}</main>
      <ScrollToTop
        smooth
        component={<FaChevronUp size={26} color="#cb5a5a" />}
        style={
          width <= 768
            ? { ...scrollBtn, bottom: 80, right: 10 }
            : { ...scrollBtn, bottom: 100, right: 40 }
        }
      />
      <Footer />
    </>
  );
}
