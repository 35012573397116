import { Text } from "../../../Atoms/Typography";
import styles from "./FooterTourList.module.scss";

export default function FooterTourList() {
  return (
    <div className="AppLayout">
      <div className={styles.FooterToursContainer}>
        <div className={styles.FooterToursListTitle}>
          <Text size="normal" bold>
            Popular Tours in Japan & Thailand
          </Text>
        </div>

        <div className={styles.FooterToursList}>
          <div>
            <b>Tokyo</b>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/59afbefb-b0d9-4e10-9273-b6c59b81e345?ref=footer"
                >
                  Tokyo Bar Hopping
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/86a08414-88c3-44cf-8111-cc3895896f45?ref=footer"
                >
                  Asakusa Walking Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/ec6f853d-0797-4ebe-b398-8bf928bfb3ec?ref=footer"
                >
                  Tsukiji Fish Market Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/collection/foodtours-tokyo?ref=footer"
                >
                  Food Tours in Tokyo
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/sumo-japan?ref=footer"
                >
                  Sumo Tours in Tokyo
                </a>
              </li>
            </ul>
          </div>
          <div>
            <b>Kyoto</b>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/cd7ede5a-72cb-4204-858a-52be444dc1ff?ref=footer"
                >
                  Kyoto Bar Hopping
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/ff345d77-8741-4669-aef1-e0d979c2361f?ref=footer"
                >
                  Kyoto Foodie Tour
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/b0423202-29dd-4399-b047-9c6a0ee31ea5?ref=footer"
                >
                  Nishiki Market Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/5375d9fa-7def-4938-b006-b97a44a4bd2e?ref=footer"
                >
                  Fushimi Inari Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/collection/foodtours-kyoto?ref=footer"
                >
                  Kyoto Food Tours
                </a>
              </li>
            </ul>
          </div>
          <div>
            <b>Osaka</b>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/a9a2ebd9-ced6-4793-a0cf-14488d92b03a?ref=footer"
                >
                  Osaka Bar Hopping
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/763e12a9-ef93-4ae4-8e17-cf86eda92b3f?ref=footer"
                >
                  Osaka Foodie Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/7ed36da2-ba55-4267-8e8b-7f885ee6d901?ref=footer"
                >
                  Kuromon Market Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/fcd0a539-dc55-4c44-8689-94e577d4bfcd?ref=footer"
                >
                  Osaka Bike Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/collection/foodtours-osaka?ref=footer"
                >
                  Osaka Food Tours
                </a>
              </li>
            </ul>
          </div>
          <div>
            <b>Bangkok</b>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/a3059bac-3c51-4723-b936-4b301e9c6fed?ref=footer"
                >
                  Bangkok Bar Hopping
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/469aeeb1-a422-4ffc-8f31-eda8decd628d?ref=footer"
                >
                  Bangkok Food Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/f882f7f6-4213-41c4-8804-779452f46b54?ref=footer"
                >
                  Bangkok Bike Tour
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/product/b8b2e052-ca30-4da8-86fa-9e45bcaf54a2?ref=footer"
                >
                  Bang Rak Market Tour
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
