import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { MdStarBorder as MenuStarIcon } from "react-icons/md";
import { RxCaretDown } from "react-icons/rx";
import ReactModal from "react-modal";
import { EventTracker } from "../../Atoms/Analytics";
import CurrencyPicker from "../../Atoms/CurrencyPicker";
import SelectButton from "../../Atoms/SelectButton";
import SearchBar from "../../Cards/SearchBar";
import CheckoutHeader from "../CheckoutHeader";
import Logo from "../Logo";
import DestinationPicker from "./DestinationPicker";
import styles from "./Header.module.scss";
import MobileDestinationPicker from "./MobileDestinationPicker";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showCurrency, setShowCurrency] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === "/bookings/[id]") {
      setShowCurrency(false);
    } else {
      setShowCurrency(true);
    }
  }, [router]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false); // Close the modal on route change
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  const isCheckout =
    router.pathname === "/bookings/[id]" ||
    router.pathname === "/checkout/[id]" ||
    router.pathname === "/payments/[id]"
      ? true
      : false;

  const hideHeader =
    isCheckout ||
    router.pathname === "/guide/[guideId]/sign-contracts" ||
    router.pathname === "/guide/sign-contracts-success";

  return (
    <>
      {isCheckout ? <CheckoutHeader /> : null}
      <div
        id="AppHeader"
        className={styles.AppHeader}
        style={hideHeader ? { display: "none" } : null}
      >
        <div className="AppLayout" style={{ overflow: "inherit" }}>
          <div className={styles.AppHeaderInner}>
            <Link
              href="/?ref=logo"
              title="MagicalTrip Homepage"
              className={styles.AppHeaderLogo}
            >
              <Logo height={40} color="#fff" />
            </Link>

            <CountryPicker />
            <EventTracker
              onClick={{
                category: "header",
                action: "click",
                label: "destination-picker",
              }}
            >
              <SelectButton pressed={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <p>Discover</p>
                <RxCaretDown size={24} style={{ margin: "0px -6px" }} />
              </SelectButton>
            </EventTracker>
            <ReactModal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              ariaHideApp={false}
            >
              <MobileDestinationPicker router={router} />
            </ReactModal>
            <div className={styles.ButtonGroup}>
              <SearchBar variant="tiny" />

              <nav
                className={styles.AppHeaderNav}
                style={{ alignItems: "flex-end" }}
              >
                {showCurrency ? (
                  <ul>
                    <li className={styles.CurrencyDropDown}>
                      <CurrencyPicker />
                    </li>
                  </ul>
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <SiteWideMessage /> */}
    </>
  );
}

function CountryPicker() {
  const router = useRouter();
  const [visibleSubMenu, setVisibleSubMenu] = useState<string | null>(null);

  const handleMouseEnter = (country: string) => setVisibleSubMenu(country);
  const handleMouseLeave = () => setVisibleSubMenu(null);

  return (
    <nav
      title="Search by city navigation"
      className={styles.AppHeaderCityPicker}
    >
      <ul>
        <li
          className={styles.SubMenuParent}
          onMouseEnter={() => handleMouseEnter("japan")}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            href={`/tours-japan?ref=nav`}
            className={styles.AppHeaderButton}
            onClick={handleMouseLeave}
          >
            <MenuStarIcon size={18} />
            <span> Japan</span>
          </Link>
          <div
            style={
              visibleSubMenu === "japan"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className={styles.SubMenuArrow}></div>
            <DestinationPicker
              countryId={"a032f3f0-81a8-41ac-bf04-f46e3be952a5"}
              onCityPick={(slug) => {
                router.push(`/tours-japan/${slug}`);
              }}
            />
          </div>
        </li>

        <li
          className={styles.SubMenuParent}
          onMouseEnter={() => handleMouseEnter("thailand")}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            href={`/tours-thailand?ref=nav`}
            className={styles.AppHeaderButton}
            onClick={handleMouseLeave}
          >
            <MenuStarIcon size={18} />
            <span> Thailand</span>
          </Link>
          <div
            style={
              visibleSubMenu === "thailand"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className={styles.SubMenuArrow}></div>
            <DestinationPicker
              countryId={"d6ab9244-a663-43b3-b5ac-92064879d22b"}
              onCityPick={(slug) => {
                router.push(`/tours-thailand/${slug}`);
              }}
            />
          </div>
        </li>
      </ul>
    </nav>
  );
}
