import cx from "classnames";
import type { ReactNode } from "react";

export type ButtonProps = {
  children: ReactNode;
  large?: boolean;
  small?: boolean;
  phantom?: boolean;
  dark?: boolean;
  link?: boolean;
  disabled?: boolean;
  type?: "submit" | "button";
  style?: React.HTMLAttributes<HTMLButtonElement>["style"];
  onClick?: () => void;
};

export default function Button({
  type = "button",
  children,
  small,
  large,
  phantom,
  dark,
  disabled = false,
  link,
  ...props
}: ButtonProps) {
  const className = cx({
    Button: true,
    "Button--dark": dark,
    "Button--phantom": phantom,
    "Button--small": small,
    "Button--large": large,
  });
  return link ? (
    <span className={className}>
      <span className="ButtonText">{children}</span>
    </span>
  ) : (
    <button type={type} disabled={disabled} {...props} className={className}>
      <span className="ButtonText">{children}</span>
    </button>
  );
}
