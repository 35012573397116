import { gql, useQuery } from "@apollo/client";
import Link from "next/link";
import { Location, LocationTranslation } from "../../../../graphql/operations";
import { getCityUrl } from "../../../../utils/getCityUrl";
import { Text } from "../../../Atoms/Typography";
import styles from "./FooterNavigation.module.css";

export default function FooterNavigation() {
  const { loading, error, data } = useQuery<FooterQueryResponse>(FOOTER_QUERY, {
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return <>loading</>;
  }

  if (error || !data || !data.locations) {
    return <>Could not load cities.</>;
  }

  return (
    <div className="AppLayout">
      <div className={styles.FooterNavigationContainer}>
        <div className={styles.FooterNavigationTitle}>
          <Text size="normal" bold>
            Tours in Japan & Thailand
          </Text>
        </div>
        <div>
          <ul className={styles.FooterNavigation}>
            {(data.locations.records || []).map(
              (location) =>
                location &&
                (location.translation && location.translation.name !== null ? (
                  <li key={location.id}>
                    <Link
                      href={`/tours-${location.country.name.toLowerCase()}/${getCityUrl(
                        location
                      )}?ref=footer_nav`}
                      className={styles.FooterNavigationItem}
                    >
                      <span>{location.translation.name} tours</span>
                    </Link>
                  </li>
                ) : null)
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

type FooterQueryResponse = {
  locations: {
    totalRecords: number;
    records: (Pick<Location, "id" | "country" | "slug"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

const FOOTER_QUERY = gql`
  query FooterQuery {
    locations(
      input: {
        hasProducts: true
        locationTypes: [PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      totalRecords
      records {
        id
        slug
        country {
          name
        }
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
