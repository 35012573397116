import { format } from "date-fns";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import useWindowSize from "../../../utils/useWindowSize";
import { useSearchContext } from "./SearchProvider";
import NormalSearchBar from "./variants/NormalSearchBar";
import SmallSearchBar from "./variants/SmallSearchBar";
import TinySearchBar from "./variants/TinySearchBar";

type SearchBarProps = {
  variant?: "tiny" | "small" | "normal" | "responsive";
  countryId?: string;
  locationId?: string;
  tags?: string[];
  reference?: string;
};

export default function SearchBar({
  variant = "responsive",
  reference,
  ...props
}: SearchBarProps) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const { width } = useWindowSize();

  const searchContext = useSearchContext();

  const [locationId, setLocationId] = useOptionalState(props.locationId, [
    searchContext.locationId,
    searchContext.setLocationId,
  ]);
  const [countryId, setCountryId] = useOptionalState(props.countryId, [
    searchContext.countryId,
    searchContext.setCountryId,
  ]);

  const [tags, setTags] = useOptionalState(props.tags && new Set(props.tags), [
    searchContext.tags,
    searchContext.setTags,
  ]);

  const performSearch = async () => {
    setLoading(true);
    searchContext.setLocationId(locationId);
    searchContext.setTags(tags);
    await router.push({
      pathname: "/search",
      query: {
        from: formatDate(searchContext.from),
        until: formatDate(searchContext.until ?? searchContext.from),
        groupSize: searchContext.numGuests,
        tagIds: JSON.stringify(Array.from(tags)),
        ...(locationId && { locationId }),
        ...(reference && { ref: reference }),
      },
    });
    setLoading(false);
  };

  const searchBarProps = {
    ...searchContext,
    locationId,
    setLocationId,
    countryId,
    setCountryId,
    tags,
    setTags,
    loading,
    performSearch,
  };

  const calculatedVariant = useMemo(
    () =>
      (variant === "responsive"
        ? width <= 320
          ? "tiny"
          : width <= 640
          ? "small"
          : "normal"
        : variant) ?? "normal",
    [variant, width]
  );

  return {
    tiny: TinySearchBar,
    small: SmallSearchBar,
    normal: NormalSearchBar,
  }[calculatedVariant](searchBarProps);
}

const formatDate = (date: Date) => format(date, "yyyy-MM-dd");

function useOptionalState<T>(
  value: T | undefined,
  existingUseState: [T, React.Dispatch<React.SetStateAction<T>>]
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const internalUseState = useState(value);

  const coalescedUseState = useMemo(
    () => (value === undefined ? existingUseState : internalUseState),
    [value, existingUseState, internalUseState]
  );

  return coalescedUseState;
}
