import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { useMemo } from "react";
import { keywordMap } from "../../../core/enums";
import { Location, LocationTranslation } from "../../../graphql/operations";
import navData from "../../../prebuild/data/navDataGenerated.json";
import { getCityUrl } from "../../../utils/getCityUrl";
import Loader from "../../Atoms/Loader";
import { Text } from "../../Atoms/Typography";
import styles from "./Header.module.scss";

type DestinationPickerProps = {
  onCityPick: (locationId: Location["id"] | undefined) => void;
  selectedId?: string;
  includeAnywhere?: boolean;
  countryId?: string;
};

export default function DestinationPicker({
  onCityPick,
  selectedId,
  countryId,
}: DestinationPickerProps) {
  const initialNavData = navData as unknown as GetLocationsResponse;
  const { loading, data } = useQuery<GetLocationsResponse>(GET_NAV_LOCATIONS, {
    fetchPolicy: "cache-and-network",
  });

  const locationsToUse = useMemo(
    () => data?.locations.records ?? initialNavData?.locations.records ?? [],
    [initialNavData, data]
  );

  if (loading && locationsToUse.length === 0) {
    return (
      <ul className={`${styles.SubMenu} ${styles.DestinationPicker}`}>
        <li style={{ width: 250 }}>
          <Loader />
        </li>
      </ul>
    );
  }

  return (
    <ul className={`${styles.SubMenu} ${styles.DestinationPicker}`}>
      {(countryId
        ? locationsToUse.filter((c) => c.country.id === countryId)
        : locationsToUse || []
      ).map(
        (location) =>
          location && (
            <DestinationListItem
              key={location.id}
              destinationNames={location.destinationNames}
              id={location.id}
              url={getCityUrl(location)}
              name={location.translation.name}
              onCityPick={onCityPick}
              selectedId={selectedId}
            />
          )
      )}
    </ul>
  );
}

type DestinationListItemsProps = {
  id: Location["id"] | undefined;
  destinationNames: Location["destinationNames"];
  url: string;
  name: Location["translation"]["name"];
} & Pick<DestinationPickerProps, "onCityPick" | "selectedId">;

function DestinationListItem({
  id,
  name,
  onCityPick,
  url,
  selectedId,
  destinationNames,
}: DestinationListItemsProps) {
  return (
    <li key={id} onClick={() => onCityPick(url)}>
      <div
        className={classNames(styles.DestinationPickerItem, {
          [styles.Selected]: id === selectedId,
        })}
      >
        <b>{name}</b>
        <Text size="smaller" muted>
          {destinationNames ?? keywordMap[id]}
        </Text>
      </div>
    </li>
  );
}

export type GetLocationsResponse = {
  locations: {
    records: (Pick<Location, "id" | "slug" | "country" | "destinationNames"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

export const GET_NAV_LOCATIONS = gql`
  query DestinationPickerQuery {
    locations(
      input: {
        hasProducts: true
        locationTypes: [PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      records {
        id
        slug
        destinationNames
        country {
          id
          name
        }
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
