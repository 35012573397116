import { cloneElement, useState } from "react";
import { ModalProps } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import BasicModal from "../../../../Atoms/BasicModal";
import Button from "../../../../Atoms/Button";
import DateRangeSearchCard from "../../../MultiCardModal/components/DateRangeSearchCard";
import GuestSearchCard from "../../../MultiCardModal/components/GuestSearchCard";
import LocationSearchCard from "../../../MultiCardModal/components/LocationSearchCard";
import { SearchCardProps } from "../../../MultiCardModal/components/SearchCard";
import SearchTodayTomorrowSearchCard from "../../../MultiCardModal/components/SearchTodayTomorrowCard";
import TagSearchCard from "../../../MultiCardModal/components/TagSearchCard";
import { SearchBarProps } from "../../types";

type SearchModalProps = ModalProps & Omit<SearchBarProps, "loading">;

export default function SearchModal({
  open,
  onClose,
  performSearch,
  from,
  until,
  setFrom,
  setUntil,
  locationId,
  countryId,
  setCountryId,
  setLocationId,
  numGuests,
  setNumGuests,
  tags,
  setTags,
  tagOptions,
}: SearchModalProps) {
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(0);
  const expansionProps = (index: number) =>
    ({
      state: expandedCardIndex === index ? "EXPANDED" : "COLLAPSED",
      expandCard: () => setExpandedCardIndex(index),
      expandNextCard: () => setExpandedCardIndex(index + 1),
    } satisfies Pick<
      SearchCardProps,
      "state" | "expandCard" | "expandNextCard"
    >);

  const searchAndClose = async (): Promise<void> => {
    onClose();
    setTimeout(() => setExpandedCardIndex(0), 300);
    performSearch();
  };

  const searchCards = [
    // TODO: short title and title is similar to airbnb. Rewrite to something else?
    <LocationSearchCard
      key={0}
      shortTitle="Where"
      title="Where to?"
      locationId={locationId}
      setLocationId={setLocationId}
      countryId={countryId}
      setCountryId={setCountryId}
    />,
    <TagSearchCard
      key={1}
      shortTitle={"What"}
      title={"What to do?"}
      tags={tags}
      setTags={setTags}
      tagOptions={tagOptions}
    />,
    <SearchTodayTomorrowSearchCard
      key={4}
      setFrom={setFrom}
      setUntil={setUntil}
      shortTitle=""
      title=""
      searchAndClose={searchAndClose}
    />,
    <DateRangeSearchCard
      key={2}
      shortTitle="When"
      title="When's your trip?"
      from={from}
      setFrom={setFrom}
      until={until}
      setUntil={setUntil}
    />,
    <GuestSearchCard
      key={3}
      shortTitle="Who"
      title="Who's coming?"
      numGuests={numGuests}
      setNumGuests={setNumGuests}
    />,
  ].map((component, index) => cloneElement(component, expansionProps(index)));

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      endComponent={
        expandedCardIndex !== searchCards.length - 1 ? (
          <Button
            dark
            onClick={() => {
              setExpandedCardIndex((index) => (index === null ? 0 : index + 1));
            }}
          >
            Next
          </Button>
        ) : (
          <Button onClick={searchAndClose}>Search</Button>
        )
      }
    >
      {searchCards.map((card) => card)}
    </BasicModal>
  );
}
